<template>
  <div>
      <v-dialog 
        v-model="opened" 
        :persistent="persistent" 
        :hide-overlay="!overlay" 
        :width="600" 
      >
      <v-card>
        <v-card-title class="hlsPopupWrapper">
          <div class="popup_header">
            <div class="popup_header_item1">HEAVY LIFTING PROGRAM - SCHEDULES</div>
          </div>
          
          <div v-ripple class="popup_close">
            <button class="button_close" title="Close" @click="onClose">
              <i class="mdi mdi-close"></i>
            </button>
          </div>
        </v-card-title>

        <div class="contents_wrapper">
          <div class="group_box column">
            <label>Common</label>

            <div class="item_row">
              <div class="contents_title">Tag No.</div>
              <div class="contents_text">{{ hlSchedule.TAG_NO }}</div>
              <div class="contents_title">Shipment No.</div>
              <div class="contents_text">{{ hlSchedule.SHIPMENT_NO }}</div>
            </div>

            <div class="item_row">
              <div class="contents_title">Package</div>
              <div class="contents_text">{{ hlSchedule.PACKAGE_CODE }}</div>
              <div class="contents_title">Area</div>
              <div class="contents_text">{{ hlSchedule.AREA_CODE }}</div>
            </div>
          </div>
        </div>

        <div class="space_v"></div>

        <div class="contents_wrapper">
          <div class="field_row">
            <div class="group_box column">
              <label>Heavy Lifting Schedule</label>

              <div class="item_row">
                <div class="contents_title">Activity Name</div>
                <div class="contents_text">{{ hlSchedule.ACTIVITY_NAME }}</div>
              </div>
              <div class="item_row">
                <div class="contents_title">Crane</div>
                <div class="contents_text">{{ hlSchedule.CRANE_TYPE }}</div>
              </div>
              <div class="item_row">
                <div class="contents_title">Duration (d)</div>
                <div class="contents_text">{{ hlSchedule.DUR_DAY }}</div>
              </div>
              <div class="item_row">
                <div class="contents_title">Plan</div>
                <div class="contents_text">{{ hlSchedule.PLAN_START_DATE }} - {{ hlSchedule.PLAN_END_DATE }}</div>
              </div>
              <div class="item_row">
                <div class="contents_title">Forecast</div>
                <div class="contents_text">{{ hlSchedule.FC_START_DATE }} - {{ hlSchedule.FC_END_DATE }}</div>
              </div>
              <div class="item_row">
                <div class="contents_title">Actual</div>
                <div class="contents_text">{{ hlSchedule.ACT_START_DATE }} - {{ hlSchedule.ACT_END_DATE }}</div>
              </div>
            </div>

            <div class="space_h"></div>

            <div class="group_box column">
              <label>MOF Utilization Schedule</label>

              <div class="item_row">
                <div class="contents_title">Vendor</div>
                <div class="contents_text">{{ mofSchedule.LOGI_VENDOR }}</div>
              </div>
              <div class="item_row">
                <div class="contents_title">Duration (d)</div>
                <div class="contents_text">{{ mofSchedule.DUR_DAY }}</div>
              </div>
              <div class="item_row">
                <div class="contents_title">Plan</div>
                <div class="contents_text">{{ mofSchedule.PLAN_ARR_DATE }} - {{ mofSchedule.PLAN_DEP_DATE }}</div>
              </div>
              <div class="item_row">
                <div class="contents_title">Forecast</div>
                <div class="contents_text">{{ mofSchedule.FC_ARR_DATE }} - {{ mofSchedule.FC_DEP_DATE }}</div>
              </div>
              <div class="item_row">
                <div class="contents_title">Actual</div>
                <div class="contents_text">{{ mofSchedule.ACT_ARR_DATE }} - {{ mofSchedule.ACT_DEP_DATE }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer_wrapper">
          <a href="#" @click="onRequest">▶ Cargo Information</a>
          <div class="notice_wrapper">
            {{ notice }}
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import QueryLibService from '../services/database.support.service'

export default {
  name: 'j-modal-equipment-popup',
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
        filters: {},
        data: {
          persistent: false,
          overlay: true,
        }
      })
    },
    value: null,
  },
  data: () => ({
    queryLibService: null,
    hlSchedule: {},
    mofSchedule: {},
    notice: '',
  }),
  computed: {
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
    persistent() { return this.params.data && this.params.data.persistent },
    overlay() { if(!this.params.data) return true; else return this.params.data.overlay; },
  },
  watch: {
    'params.filters': {
      handler() {
        this.getDataItems()
      },
      deep: true
    },  
  },
  created() {
    this.queryLibService = new QueryLibService()
  },
  methods: {
    onClose() {
      this.opened = false
    },
    onRequest(){
      this.notice = ''

      if (!this.hlSchedule.SHIPMENT_NO) {
        this.notice = 'No Shipment-No.'
        return
      }

      this.$emit('request-action', {
        action: {
          target: 'popup-component-free',
          component: 'HeavyLiftingCargoInfo',
        },
        filters: { TASK: this.hlSchedule.SHIPMENT_NO },
        data: {
          title: 'Cargo Information For',
          persistent: true,
          overlay: false
        }
      })
    },
    getDataItems() {
      this.notice = ''
      let filters_ = this.params.filters || {}

      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: 'Heavy Lifting Schedule By Tag No.',
        filters: `TAG_NO = '${filters_.TAG_NO}'`
      }).then(res => {
        this.hlSchedule = res ? res[0] : {}
        
        this.queryLibService.getSqlQueryResult({ 
          idx: 0, 
          name: 'MOF Utilization Schedule By Tag No.',
          filters: `TAG_NO = '${filters_.TAG_NO}'`
        }).then(res => {
          this.mofSchedule = res ? res[0] : {}
        })
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
.hlsPopupWrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.popup_header {
  display: flex;
  justify-content: space-between;
  width: 38rem;
  font-size: 1rem;
  padding-top: 5px;
  padding-bottom: 15px;
  pointer-events: none;

  .popup_header_item1{
    color: #000;
  }
  .popup_header_item2{
    color:#e5e5e5;
  }
}
.popup_close{
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  padding-top: .1rem;
  padding-left: .2rem;

  .mdi-close {
    font-size: 1.4rem;
  }
}

.group_box {
  position: relative;
  display: flex;

  padding: 1.3rem 1rem 1rem 1rem;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 3px;

  &.column {
    flex-direction: column;
  }

  label {
    position: absolute;
    top: -.6rem;
    left: .5rem;

    line-height: 1.2rem;
    padding: 0 1rem;
    background-color: #fff;
  }
}

.space_v {
  height: 1rem;
}
.space_h {
  width: 1rem;
}

.contents_wrapper {
  padding: 0 1.5rem;

  .field_row {
    display: flex;
  }

  .contents_title{
    color: #9E9E9E;
    width: 7rem;
    text-align: end;
    margin-right: 1rem;
    line-height: 1.8rem;
  }
  .contents_text{
    width: 18rem;
    line-height: 1.8rem;
  }

  .item_row {
    display: flex;
  }
}

.footer_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1.5rem;

  .notice_wrapper {
    color: #f33f3f;
  }
}
</style>